/* eslint-disable no-process-env */
export const APP_VERSION = process.env.APP_VERSION;
export const APP_CLIENT_VERSION = process.env.APP_CLIENT_VERSION;
export const APP_URL = (
	process.env.CLIENT_TYPE === 'conductor'
		? process.env.CONTENTKING_CONDUCTOR_APP_URL
		: process.env.CONTENTKING_APP_URL
);



export const ASSETS_STORAGE_VERSIONLESS = process.env.ASSETS_STORAGE_VERSIONLESS;



export const CALENDLY_BOOKING_URL = process.env.CALENDLY_BOOKING_URL;



export const CLIENT_TYPE = process.env.CLIENT_TYPE;



export const CONDUCTOR_APP_URL = process.env.CONDUCTOR_APP_URL;



export const CONTENTKING_API_URL = process.env.CONTENTKING_API_URL;
export const CONTENTKING_APP_URL = process.env.CONTENTKING_APP_URL;
export const CONTENTKING_CONDUCTOR_APP_URL = process.env.CONTENTKING_CONDUCTOR_APP_URL;
export const CONTENTKING_ENVIRONMENT = process.env.CONTENTKING_ENVIRONMENT;



export const GOOGLE_API_CLIENT_ID = process.env.GOOGLE_API_CLIENT_ID;



export const RECURLY_PUBLIC_KEY = process.env.RECURLY_PUBLIC_KEY;



export const SENTRY_ENABLED = process.env.SENTRY_ENABLED;
export const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;
export const SENTRY_URL = process.env.SENTRY_URL;



export const SLACK_CLIENT_ID = process.env.SLACK_CLIENT_ID;
export const SLACK_REDIRECT_URL = process.env.SLACK_REDIRECT_URL;



export const STRIPE_PUBLIC_KEYS = process.env.STRIPE_PUBLIC_KEYS;



export const IsConductorClient = CLIENT_TYPE === 'conductor';
export const IsContentKingClient = CLIENT_TYPE === 'contentking';
